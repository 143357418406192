<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			label-width="68px"
		>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="报表类型" prop="name">
						<el-select
							v-model="queryParams.reportType"
							placeholder="请选择报表类型"
							size="small"
							style="width: 100%"
						>
							<el-option label="全部" value=""></el-option>
							<el-option
								v-for="(value, key) in dicts.REPORT_TYPE"
								:key="value"
								:label="value"
								:value="key"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="上游通道" prop="channelNo">
						<el-select
							v-model="queryParams.channelNo"
							placeholder="请选择上游通道"
							style="width: 100%"
							clearable
						>
							<el-option
								v-for="item in acqChannelList"
								:label="item.name"
								:value="item.channelNo"
								:key="item.channelNo"
							>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item
						label="生成日期范围"
						prop="createDateRange"
						label-width="96px"
					>
						<el-date-picker
							size="small"
							type="daterange"
							v-model="createDateRange"
							@change="dateChange"
							range-separator="-"
							value-format="yyyy-MM-dd"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:picker-options="pickerOptions"
							style="width: 100%;"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- 按钮 -->
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleAdd"
					v-permission="'EXECL_DOWNLOAD_INFO_INSERT'"
				>
					生成
				</el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'EXECL_DOWNLOAD_INFO_QUERY'"
				>
					查询
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { FinancialToolsApi, TerminalApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			// queryParams: {
			// 	reportType: "",
			// 	channelNo: "",
			// 	startCreateTime: "",
			// 	endCreateTime: ""
			// },
			queryParams: {}, // 表单数据
			acqChannelList: [], //上游通道
			createDateRange: [], //创建时间
			showCustomForm: false,
			minDate: null,
			maxDate: null,
			// 日期区间数据
			// pickerOptions: {
			// 	//方法一 计算当前选中日期六个月之间
			// 	onPick: ({ maxDate, minDate }) => {
			// 		//选择的小的日期  选择一个日期时的值
			// 		this.min = minDate && minDate.getTime();
			// 		if (maxDate) {
			// 			//如果两个日期都选择 该变量就有值
			// 			this.min = "";
			// 		}
			// 	},
			// 	disabledDate: time => {
			// 		let m = 6 * 30 * 24 * 60 * 60 * 1000;
			// 		if (this.min) {
			// 			return (
			// 				time.getTime() > this.min + m ||
			// 				time.getTime() < this.min - m ||
			// 				time.getTime() > Date.now()
			// 			);
			// 		} else {
			// 			return time.getTime() > Date.now();
			// 		}
			// 	},
			// 	// 方法二 计算当前日期六个月前的日期
			// 	disabledDate(time) {
			// 		// 计算当前日期六个月前的日期
			// 		const sixMonthsAgo = new Date();
			// 		sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
			// 		// 计算当前日期
			// 		const today = new Date();
			// 		// 如果时间早于六个月前或晚于当前日期，则禁用
			// 		return (
			// 			time.getTime() < sixMonthsAgo.getTime() ||
			// 			time.getTime() > today.getTime()
			// 		);
			// 	}
			// },
			// 日期6个月区间数据
			isRestart: false, // 初始选中为false
			pickerMinDate: "", // 选中最小的时间
			pickerOptions: {
				// 解构最大最小时间
				onPick: ({ maxDate, minDate }) => {
					this.isRestart = false;
					// getTime()时间戳
					this.pickerMinDate = minDate.getTime();
					// 如果存在maxDate则将pickerMinDate赋值为空
					if (maxDate) {
						this.pickerMinDate = "";
					}
				},
				disabledDate: time => {
					// 判断pickerMinDate不为空才执行
					if (this.pickerMinDate !== "") {
						// 6个月，30天，24小时，3600秒，1000毫秒
						const one = 6 * 30.5 * 24 * 3600 * 1000;
						const minTime = this.pickerMinDate - one;
						const maxTime = this.pickerMinDate + one;
						return (
							time.getTime() < minTime || time.getTime() > maxTime
						);
					}
				}
			}
		};
	},
	async mounted() {
		//上游通道列表
		const result = await TerminalApi.acqChannel.listAllChannel();
		this.acqChannelList = (result && result.data) || [];
		// 打印
		console.log("this.dicts----->", this.dicts);
	},
	methods: {
		// 查询事件
		handleQuery() {
			this.commitChange();
		},
		// 生成事件
		handleAdd() {
			// 判断不选中日期则提示错误
			if (
				!this.createDateRange ||
				!this.createDateRange[0] ||
				!this.createDateRange[1]
			) {
				this.$message.error("请选择生成日期范围");
				return;
			}
			this.$confirm("确定生成分润明细", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				// 保存分润明细excel异步导出
				let result = await FinancialToolsApi.execlDownloadInfo.postExeclDownloadInfo(
					this.queryParams
				);
				console.log("postExeclDownloadInfo----->145", result);
				if (result.success) {
					this.handleQuery();
				}
			});
		},
		//导出功能
		handleExport() {
			this.$confirm("确定导出代扣订单", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				// 获取导出代理商调账记录
				let result = await FinancialToolsApi.execlDownloadInfo.export(
					this.queryParams
				);
				console.log("export----->154", result);
				// 判断成功则调用下载
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		// 日期事件
		dealWithQueryParams() {
			console.log("dealWithQueryParams----->", this.createDateRange);
			// 这里要重置为空数组，判断方法再改下null或空数组
			if (!this.createDateRange) {
				this.createDateRange = [];
			}
			// 开始创建时间,截至创建时间
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";
		},
		// 查询提交事件
		commitChange() {
			// 调用日期事件
			this.dealWithQueryParams();
			// // 展开保存表单数据--浅拷贝
			let params = { ...this.queryParams };
			// // 传递事件携带参数给父组件
			this.$emit("on-change", params);
		},
		// 触发日期事件
		dateChange(val) {
			// 判断没有选中数据把transTime赋值为空
			if (!val) {
				this.queryParams.transTime = "";
			} else {
				// 把调整日期的对象数据转换为JSON字符串，用逗号拼接起来。
				// 再将字符串数据解析为对象————深拷贝
				this.queryParams.transTime = JSON.parse(
					JSON.stringify(val)
				).join(",");
				console.log("this.queryParams----->271", this.queryParams);
			}
		}
	}
};
</script>

<style scoped></style>
